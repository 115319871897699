<template>
  <div
    v-on="$listeners"
    class="cursor-pointer flex mb-5 p-5 pt-7 relative rounded-sm transition w-full"
    :class="[
      isActive ? 'bg-corporate-800' : 'bg-corporate-900 hover:bg-corporate-600',
      isDone ? 'hiddens' : 'blocks',
    ]"
  >
    <div
      v-if="option.name === 'work-email'"
      class="absolute bg-green-500 bottom-full -mb-4 pointer-events-none px-5 py-2 rounded-sm text-xs text-white"
    >
      Recommended for working professionals
    </div>
    <div
      class="flex h-30px items-center justify-center mt-1 rounded-full text-blue-500 text-xl w-30px"
      :class="isActive ? 'bg-white' : 'bg-gray-300'"
      style="flex: 0 0 30px"
    >
      <ion-icon name="checkmark-outline" v-if="isActive"></ion-icon>
    </div>
    <div
      class="flex flex-col ml-5"
      :class="isActive ? 'text-white' : 'text-black'"
    >
      <p class="font-semibold mb-2 text-12">
        {{ label }}
      </p>
      <ul class="font-thin ml-4 text-10" style="list-style: initial;">
        <template v-for="(description, descriptionIndex) in description">
          <li :key="descriptionIndex">
            {{ description }}
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AccountVerificationSelectionOption',
    props: {
      isDone: {
        type: Boolean,
        default: false
      },
      option: {
        type: Object,
        default: () => null
      },
      isSelected: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      description() {
        return this.option?.description;
      },
      isActive() {
        return this.isSelected || this.isDone;
      },
      label() {
        return this.option?.label;
      }
    },
  }
</script>

<style lang="sass" scoped>
.ion-icon
  .icon-inner svg path
    stroke-width: 70px
</style>
